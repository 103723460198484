<template>
  <div>
    <Card>
      <template #header>İlgilenen Müşteriler</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="addCustomer = true"
              >Yeni</b-button
            ></b-form-group
          >
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="İlgilenen Müşteriler Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end">
          <button class="btn p-0">
            <i class="flaticon-eye text-dark text-md"></i>
          </button>
          <button class="btn p-0" @click="openDeleteHandler(item)">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon-download text-dark text-md"></i>
          </button>
        </div>
      </template>
    </List>
    <b-modal v-model="addCustomer" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i> Yeni Müşteri</h4>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="">İlgilenen Müşteri</label>
            <MultiSelect
              :options="users"
              :multiple="false"
              v-model="interested_customer"
            />
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addCustomer = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="addNewNote" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <DeleteOperation
      :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
      modalid="kisiler"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { CUSTOMERS } from "@/core/services/store/customers/customers.module";

export default {
  name: "systemUsers",
  data() {
    return {
      addCustomer: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Müşteri", value: "interested_customer" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      users: [],
      interested_customer: "",
    };
  },

  created() {
    this.getList();
    this.getUsers();
  },
  methods: {
    save() {
      let data = {
        interested_customer: this.interested_customer,
        user_token: this.myUser,
        property_id: this.$route.params.id,
      };
    },
    getList() {
      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `property/contacts?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&property_id=${
        this.$route.params.id
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          this.items = res.response.data;
          this.totalItems = res.response.count;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = `property/contacts`;
      this.deleteIds = `?user_token=${this.myUser}&interested_customer=${item._id.$oid}&property_id=${this.$route.params.id}`;
      this.$bvModal.show('modal-deleteOperationdeletekisiler');
    },
    getUsers() {
      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `user/user?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          if (res.status.code === 200) {
            this.users = res.response.data;
            
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
    users(){
      if(this.users.length > 0){
        let newUsers = []
        this.users.map((e) => {
          let userArray = {text: e.adi + " " + e.soyadi , value:e._id.$oid}
          newUsers.push(userArray)
        })
        this.users = newUsers
      }
    },
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
